import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button/button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import BlurredText from '../../../images/blur_text.png';
import { TextField } from '../Form';
import validateEmail from '../../../utils/validateEmail';
import RadioGroup from '../Form/RadioGroup/radio-group';

const Modal: ObsoleteComponent = ({
  isDisplayed = false,
  closeAction,
  formName,
  action,
  fileName,
  headerText,
  onSubmit,
}) => {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFieldNotEmpty, setIsFieldNotEmpty] = useState(false);
  const [isLeadTypeValid, setIsLeadTypeValid] = useState(false);
  const formRef = React.createRef<HTMLFormElement>();

  useEffect(() => {
    if (isDisplayed && typeof window !== undefined && window.gtag) {
      window.gtag('event', 'modal_view', {
        event_category: 'modal',
      });
    }
  }, [isDisplayed]);

  const handleFormSubmit = (e) => {
    if (onSubmit && formRef.current) {
      e.preventDefault();
      const formData = new FormData(formRef.current);
      onSubmit(formData);
    }
    if (typeof window !== undefined && window.gtag) {
      window.gtag('event', 'modal_form_submit', {
        event_category: 'modal',
        event_label: formName,
      });
    }
  };

  const checkLength = (value) => {
    value.length > 0 ? setIsFieldNotEmpty(true) : setIsFieldNotEmpty(false);
  };

  return (
    <>
      <div
        role="dialog"
        aria-labelledby="dialog1Title"
        aria-describedby="dialog1Desc"
        className={`${
          isDisplayed ? `block` : `hidden`
        } absolute flex align-center justify-center top-24 left-0 w-full z-40`}
      >
        <div className="grid grid-cols-6 gap-x-4 w-full max-w-175 m-auto">
          <div className={'bg-white col-span-6 border relative'}>
            <button onClick={closeAction} className="absolute right-0 top-0 p-3" type="secondary">
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <h1
              id="dialog1Title"
              className="font-serif text-5xl text-center border-b pb-4 pt-10 mb-12"
            >
              {headerText}
            </h1>

            {fileName && <p className="text-center pt-4 absolute top-24 w-full">{fileName}</p>}

            <img src={BlurredText} alt="blurred text" className="m-auto pl-6" />
            <div className="bg-pink-light pb-6 px-7 pt-8 w-full grid gap-x-4 grid-cols-6 items-end z-40 -mt-28 relative">
              <div className="col-span-3 place-self-start">
                <h2 className="font-serif text-2xl pb-2">View results</h2>
                <p id="dialog1Desc" className="pb-11">
                  To find out more, why not book a demo. Simply fill out the form and our team of
                  experts will get in touch shortly.
                </p>
              </div>
              <form
                ref={formRef}
                action={action}
                name={formName}
                method="POST"
                onSubmit={handleFormSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="col-span-3 grid grid-cols-1 gap-y-2 items-end"
              >
                <div>
                  <TextField
                    type="text"
                    label="Name"
                    id="name"
                    placeholder="John Doe"
                    autoComplete="given-name"
                    required
                    className="md:mb-0"
                    labelClassName="text-base mb-2"
                    onChange={(e) => checkLength(e.target.value)}
                  />
                </div>

                <div>
                  <TextField
                    type="email"
                    label="Email Address"
                    id="email-modal"
                    placeholder="name@domain.com"
                    autoComplete="email"
                    className="md:mb-0"
                    labelClassName="text-base mb-2"
                    required
                    onChange={(e) => setIsEmailValid(validateEmail(e.target.value))}
                  />
                </div>
                <RadioGroup
                  required
                  name="lead_type"
                  label="Please Select"
                  options={[
                    { label: 'Agent', value: 'Agent' },
                    { label: 'Landlord', value: 'Landlord' },
                    { label: 'Tenant', value: 'Tenant' },
                  ]}
                  onChange={() => setIsLeadTypeValid(true)}
                />
                {fileName ? <input className="hidden" name="file" type="file" /> : null}

                <div>
                  <Button
                    type="submit"
                    label="Book a demo"
                    className="mt-5"
                    disabled={!isEmailValid || !isFieldNotEmpty || !isLeadTypeValid}
                  ></Button>
                </div>
                <input type="hidden" name="form-name" value={formName} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isDisplayed ? `block` : `hidden`
        } fixed w-full h-full l-0 t-0 bg-gray opacity-50 z-30 inset-0`}
      ></div>
    </>
  );
};

export default Modal;

Modal.propTypes = {
  closeAction: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool,
  fileName: PropTypes.string,
};
