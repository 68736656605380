import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/v2/templates/Layout';
import { Section } from '../../components/v2/templates/Section';
import useStoryblok from '../../lib/storyblok';
import Bloks from '../../components/atoms/Bloks';
import queryString from 'query-string';
import Notification from '../../components/v1/molecules/notification';
import Modal from '../../components/v1/modal';
import { StoryblokPage } from '../../types';

const BusinessQualify: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  const [formStatus, setFormStatus] = useState('idle');

  //Generate a random delay time for the submit
  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max) + 1;
  };

  useEffect(() => {
    let timer;
    if (formStatus === 'submitting') {
      const delay = getRandomInt(4);
      timer = setTimeout(() => setFormStatus('submitted'), delay * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [formStatus]);

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved hero className="z-10" noGrid curveColor="tertiary">
        <Bloks bloks={content.hero} />
      </Section>

      <Bloks bloks={content.sections} />
      <Modal
        isDisplayed={formStatus === 'submitted'}
        closeAction={() => setFormStatus('idle')}
        action="/business/qualify/?formSubmit=success"
        formName="homepage-form"
        headerText="Match Found!"
      />

      <Notification isVisible={queryString.parse(location.search).formSubmit === 'success'} />
    </Layout>
  );
};

export default BusinessQualify;

export const query = graphql`
  query Business_Qualify {
    storyblokEntry(full_slug: { eq: "business/tenant-referencing" }) {
      content
      name
    }
  }
`;
