import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ label, defaultChecked, value, name, ...props }) => {
  return (
    <label className="mr-2">
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        {...props}
        className="mr-2 form-radio text-black checked:bg-none focus:outline-none"
      />
      <span>{label}</span>
    </label>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default RadioButton;
