import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from './radio-button';

const RadioGroup = ({ options, label, name, defaultValue, onChange, hideLabel, required }) => {
  return (
    <div role="radiogroup" aria-labelledby="radio_group_label" id={name}>
      <label
        htmlFor={name}
        id="radio_group_label"
        className={`${hideLabel ? `sr-only` : `block`} mb-2`}
      >
        {label}
        {required && <sup className="text-blue-700">*</sup>}
      </label>
      <div className="flex flex-row">
        {options.map(({ value, label }) => (
          <RadioButton
            onChange={onChange}
            value={value}
            label={label}
            key={value}
            defaultChecked={value === defaultValue}
            name={name}
          />
        ))}
      </div>
    </div>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
};

export default RadioGroup;
